<template>
  <div>
    <base-card>
      <v-img
        height="202"
        lazy-src="@/assets/images/banners/shop-cover.png"
        :src="user.cover || '@/assets/images/faces/ralph.png'"
      />
      <v-card-text class="p-relative">
        <div class="d-flex justify-space-between flex-wrap mt--66">
          <div class="d-flex flex-wrap">
            <v-avatar
              size="120"
              class="mr-8"
            >
              <v-img
                class="border-4-white"
                :lazy-src="user.avatar_thumb"
                :src="user.avatar"
                alt=""
              />
            </v-avatar>

            <div>
              <v-alert
                dense
                color="secondary white--text"
                class="d-inline-block mb-6"
              >
                <span class="font-weight-bold">
                  {{ user.pseudo }}
                </span>
              </v-alert>
              <div
                v-if="user.rating"
                class="d-flex align-center mb-4"
              >
                <v-rating

                  :value="user.rating || 0"
                  color="amber"
                  dense
                  half-increments
                  readonly
                  size="16"
                />

                <div class="grey--text text--darken-4 ml-1">
                  ({{ user.rating?user.rating.toFixed(1): 0 }})
                </div>
              </div>

              <div class="d-flex align-center mb-2">
                <v-icon
                  small
                  class="mr-2"
                  color="grey darken-1"
                >
                  mdi-map-marker
                </v-icon>
                <span class="grey--text text--darken-1">{{ user.postal_code }} {{ user.city }} {{ user.country_code }}</span>
              </div>
<!--              <div class="d-flex align-center mb-2">
                <v-icon
                  small
                  class="mr-2"
                  color="grey darken-1"
                >
                  mdi-phone
                </v-icon>
                <span class="grey&#45;&#45;text text&#45;&#45;darken-1">{{ user.tel }}</span>
              </div>-->
            </div>
          </div>
          <v-btn depressed color="primary" @click="openChat">{{ $t("Contacter le vendeur")}}</v-btn>
        </div>
      </v-card-text>
    </base-card>
    <v-snackbar
      v-model="snackbar.active"
      dark
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn

        text
        @click="snackbar.active = false"
      >
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import { mapGetters, mapActions, mapState, mapMutations  } from 'vuex'
  import FileUpload from '../../components/file_upload.vue'

  export default {
    components: { FileUpload },
    props: {
      user: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data: vm => {
      return {
        dateMenu: false,
        activePicker: null,
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',

        errorMessages: { name: 'Est requis' },
        salutationRules: [v => !!v || i18n.t('Requis.')],
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
        snackbar: { active: false, text: '', color: 'primary' },
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      }
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        userData: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      computedDateFormatted () {
        return this.formatDate(this.user.user.dob || this.date)
      },
      date () {
        return this.user.dob
      },
    },
    watch: {
      userData (val) {
        this.user = val
      },
      date (val) {
        this.dateFormatted = this.formatDate(this.user.user.dob || this.date)
      },
      dateMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    created () {

    },
    methods: {
      ...mapActions(['pushItem']),
      ...mapMutations([
        'updateChat', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),
      openChat () {
        if(this.userData && this.userData.id){
          if(this.user && this.user.id != this.userData.id){
            this.updateChat({type: 'user', typeId: this.user.id, title: this.user.pseudo, image: this.user.avatar,})
          }
        }else{
          this.snackbar.text = this.$t("Veuillez vous connecter pour discuter avec le vendeur.")
          this.snackbar.color = 'red'
          this.snackbar.active = true;
        }
      },
      save (date) {
        this.$refs.dateMenu.save(date)
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/users',
          data: this.user,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          //localStorage.setItem('userData', JSON.stringify(response.data))
          this.toast(this.$t('Informations enregistrées avec succès'), 'success')
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }
  .box-sidebar {
    .sidebar-slider {
      .v-messages {
        display: none;
      }
    }
  }
</style>
